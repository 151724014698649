<template>
  <main class="layout-content">
    <LayoutContentHeader v-if="layoutName === 'docs'" />
    <div :class="['layout-content-container', { 'has-max-width': hasMaxWidth }]">
      <div
        v-if="!!$slots.left"
        class="left-sidebar"
      >
        <slot name="left" />
      </div>
      <div class="main-content">
        <slot name="default" />
      </div>
      <div
        v-if="!!$slots.right"
        class="right-sidebar"
      >
        <slot name="right" />
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
const props = defineProps({
  layoutName: {
    type: String,
    required: true,
  },
})

// Non-docs pages typically should utilize PageSection components to define the max-width of the page content.
const hasMaxWidth = computed(() => props.layoutName === 'docs')
</script>

<style lang="scss" scoped>
.layout-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(100vh - #{$header-height * 2});
}

// Important: .layout-content-container class is bound in useTocActiveLink.ts
.layout-content-container {
  display: flex;
  flex: 1 1 auto; // expand to fill space
  flex-direction: row;
  gap: $kui-space-0;
  height: 100%;
  margin: $kui-space-0 $kui-space-auto;
  min-width: 0; // Important: By explicitly setting min-width: 0; on the flex item, we can override the default behavior and allow the element to shrink beyond its automatic minimum size.
  padding: $kui-space-0;
  width: 100%;

  &.has-max-width {
    max-width: $content-max-width;
    padding: $kui-space-90 $kui-space-70;
  }

  @media (min-width: $kui-breakpoint-tablet) {
    gap: $kui-space-70;
  }

  @media (min-width: $kui-breakpoint-laptop) {
    gap: $kui-space-90;
  }
}

.main-content {
  display: flex;
  flex: 1 1 auto; // expand to fill space
  flex-direction: column;
  min-width: 0; // Important: By explicitly setting min-width: 0; on the flex item, we can override the default behavior and allow the element to shrink beyond its automatic minimum size.
  width: 100%;

  :deep(> div) {
    width: 100%;
  }
}

.left-sidebar,
.right-sidebar {
  flex: 0 0 auto;

  &:empty {
    flex: 0 1 auto; // Allow to collapse if empty
  }
}
</style>
