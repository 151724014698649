// Import all of the `@kong/design-tokens` CSS Custom Properties as a inline (raw) string to make them available within custom pages and the rest of the app
import kongDesignTokensCssCustomProperties from '@kong/design-tokens/tokens/css/custom-properties.css?inline'

/**
 * Customize the Portal theme colors based on the response from the `/portal/appearance` endpoint.
 */
export default defineNuxtPlugin({
  name: 'design-token-css-variables',
  parallel: true,
  async setup() {
    // Tag priority is used to determine the order in which the tags are added to the head element.
    useHead({
      style: [
        // Load the `@kong/design-tokens` CSS Custom Properties to make them available within custom pages and the rest of the app
        { key: 'kong-design-tokens-css-custom-properties', textContent: parseCustomCss(kongDesignTokensCssCustomProperties), tagPosition: 'head', tagPriority: 0 },
      ],
    }, {

    })
  },
})
