<template>
  <LayoutGlobal>
    <NuxtLayout :name="layout">
      <NuxtPage />
    </NuxtLayout>
  </LayoutGlobal>
</template>

<script setup lang="ts">
import { KUI_COLOR_BACKGROUND_PRIMARY } from '@kong/design-tokens'
import type { LayoutKey } from '#build/types/layouts'

const route = useRoute()
// Return the layout key from `/apps/docs/layouts/*.vue` based on the current route
const layout = computed((): LayoutKey => {
  const docsLayoutRoutes = ['/getting-started', '/components']
  if (docsLayoutRoutes.some((path) => route.path.startsWith(path))) {
    return 'docs'
  }

  return 'default'
})

defineOgImage({
  component: 'NuxtSeo',
  props: {
    theme: KUI_COLOR_BACKGROUND_PRIMARY,
  },
})

const siteName = useRuntimeConfig().public.siteName || ''
useSeoMeta({
  titleTemplate: () => route.path === '/' ? siteName : `%s | ${siteName}`,
})

const { token } = useRuntimeConfig().public.scripts.cloudflareWebAnalytics
if (!import.meta.env.DEV && token) {
  useHead({
    script: [{
      src: 'https://static.cloudflareinsights.com/beacon.min.js',
      'data-cf-beacon': JSON.stringify({
        token,
        spa: true,
      }),
      defer: true,
      tagPosition: 'bodyClose',
      key: 'cloudflare-web-analytics',
    }],
  }, { mode: 'client' })
}
</script>
