import payload_plugin_Af8SBseRSH from "/work/portal/portal/node_modules/.pnpm/@pinia+nuxt@0.6.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_vfv5wGQ1kV from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8iM5lquHzk from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vZozZha80j from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Z1Uk1a0ZcB from "/work/portal/portal/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.9.0_less@4.1._k7xyef2yojf5xzarxggymhhfhe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_SjGlwebdse from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_648pASzKB0 from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RupVDk5EOE from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KkWLG3al01 from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_L1JhVQPEc5 from "/work/portal/portal/node_modules/.pnpm/@pinia+nuxt@0.6.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/work/portal/portal/apps/docs/.nuxt/components.plugin.mjs";
import prefetch_client_we4z4rwVJG from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_MsPNfHGj0b from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_CjtJ0bKVt6 from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import titles_mE0cpBYXOz from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.9.0_twoyr6vtbijbi74tnoaebmyvlu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_UhaYJIlczE from "/work/portal/portal/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.9.0_twoyr6vtbijbi74tnoaebmyvlu/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_0ZsWARlAIr from "/work/portal/portal/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.9.0_less@4_qljpuktiv5v2yud5q3v2bxkcem/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_OXDiZTtwrE from "/work/portal/portal/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.9.0_less@4_qljpuktiv5v2yud5q3v2bxkcem/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import kongponents_nlUFohuKo7 from "/work/portal/portal/layers/kongponents/plugins/kongponents.ts";
import customization_hUNLdnXNU1 from "/work/portal/portal/apps/docs/plugins/customization.ts";
export default [
  payload_plugin_Af8SBseRSH,
  revive_payload_client_vfv5wGQ1kV,
  unhead_8iM5lquHzk,
  router_vZozZha80j,
  _0_siteConfig_Z1Uk1a0ZcB,
  payload_client_SjGlwebdse,
  navigation_repaint_client_648pASzKB0,
  check_outdated_build_client_RupVDk5EOE,
  chunk_reload_client_KkWLG3al01,
  plugin_vue3_L1JhVQPEc5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_we4z4rwVJG,
  switch_locale_path_ssr_MsPNfHGj0b,
  i18n_CjtJ0bKVt6,
  titles_mE0cpBYXOz,
  defaultsWaitI18n_UhaYJIlczE,
  siteConfig_0ZsWARlAIr,
  inferSeoMetaPlugin_OXDiZTtwrE,
  kongponents_nlUFohuKo7,
  customization_hUNLdnXNU1
]