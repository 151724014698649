<template>
  <div class="layout-global">
    <NuxtLoadingIndicator :height="2" />

    <LayoutPageOverlay
      v-if="showPageOverlay"
      @click="onOverlayClose"
    />

    <LayoutHeader />

    <slot name="default" />

    <LayoutFooter />
  </div>
</template>

<script setup lang="ts">
const layoutStore = useLayoutStore()
const { showPageOverlay } = storeToRefs(layoutStore)

const onOverlayClose = (): void => {
  layoutStore.closeAllSidebars()
}

// Hide the document.body overflow when the global page overlay is active
useHeadSafe({
  bodyAttrs: {
    class: computed(() => showPageOverlay.value ? 'overflow-hidden' : ''),
  },
})
</script>

<style lang="scss" scoped>
.layout-global {
  display: flex;
  flex-direction: column;
  height: 100%;

  &,
  // The <NuxtPage /> component adds a div that must have 100% height as well; don't add a class to the <NuxtPage /> component
  :deep(> div:not(.nuxt-loading-indicator)) {
    height: 100%;
    min-height: 100%; // Important - include min-height for the sticky positioning of elements
  }

  :deep(.nuxt-loading-indicator) {
    background: var(--kui-color-background-primary-weak, $kui-color-background-primary-weak) !important;
  }
}
</style>
