<template>
  <div class="layout-content-header">
    <div class="left">
      <button
        class="content-header-button menu"
        data-testid="content-header-menu-button"
        type="button"
        @click="sidebarLayoutDocsIsVisible = true"
      >
        <BookIcon
          display="block"
          :size="KUI_ICON_SIZE_30"
        />
        <div>{{ t('navigation.docs') }}</div>
      </button>
    </div>
    <div class="right">
      <button
        v-if="sidebarTocLinks?.length"
        class="content-header-button on-this-page"
        data-testid="content-header-on-this-page-button"
        type="button"
        @click="sidebarTocIsVisible = true"
      >
        <div>{{ t('navigation.on_this_page') }}</div>
        <DocumentListIcon
          display="block"
          :size="KUI_ICON_SIZE_30"
        />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BookIcon, DocumentListIcon } from '@kong/icons'
import { KUI_ICON_SIZE_30 } from '@kong/design-tokens'
import type { TocLink } from '@nuxt/content'

const { t } = useI18n()
const { sidebarLayoutDocsIsVisible, sidebarTocIsVisible } = storeToRefs(useLayoutStore())
const sidebarTocLinks = useState<TocLink[] | undefined>('layout-sidebar-toc', () => undefined)
</script>

<style lang="scss" scoped>
.layout-content-header {
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: rgba($kui-color-background, 0.75);
  border-bottom: $kui-border-width-10 solid $kui-color-border;
  color: $kui-color-text-neutral-stronger;
  display: flex;
  font-size: $kui-font-size-30;
  font-weight: $kui-font-weight-regular;
  justify-content: space-between;
  line-height: $kui-line-height-30;
  padding: $kui-space-0 $kui-space-70;
  position: sticky;
  top: $header-height;
  width: 100%;
  z-index: 100;

  @media (min-width: $kui-breakpoint-tablet) {
    display: none;
  }

  .left,
  .right {
    align-items: center;
    display: flex;
    gap: $kui-space-40;
  }

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;
  }
}

button.content-header-button {
  align-items: center;
  background-color: $kui-color-background-transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  font-size: $kui-font-size-30;
  font-weight: $kui-font-weight-regular;
  gap: $kui-space-20;
  justify-content: center;
  line-height: $kui-line-height-30;
  padding: $kui-space-40 $kui-space-0;
  user-select: none;
  white-space: nowrap;
}
</style>
