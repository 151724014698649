<template>
  <header class="layout-header">
    <div class="layout-header-content">
      <div class="left">
        <NuxtLink
          class="logo"
          to="/"
        >
          <KongGradientIcon
            decorative
            display="block"
          />
          <div><span class="konnect">Konnect </span>Portal Docs</div>
        </nuxtlink>
      </div>
      <div class="center">
        <nav
          v-if="navigation?.length"
          class="main-navigation"
        >
          <ul>
            <li
              v-for="link of navigation"
              :key="link._path"
            >
              <NuxtLink
                class="header-link"
                :class="{ 'router-link-active': getActiveClass(link._path) }"
                :to="link._path"
              >
                {{ link._path === '/getting-started' ? t('navigation.docs') : link.title }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
      <div class="right" />
    </div>
  </header>
</template>

<script setup lang="ts">
import { KongGradientIcon } from '@kong/icons'

// Fetch content navigation
const { data: navigation } = await useAsyncData('header-navigation', () => fetchContentNavigation(queryContent({
  where: [{
    $and: [
      // Exclude the home page
      { _path: { $ne: '/' } },
      // Exclude `/components/**` paths
      { _path: { $not: { $icontains: '/components' } } },
    ],
  }],
})))

const { t } = useI18n()
const route = useRoute()
const getActiveClass = (path: string) => {
  return (
    path.includes('/getting-started') && route.path.includes('/getting-started/') ||
    path.includes('/templates') && route.path.includes('/templates/')
  )
}
</script>

<style lang="scss" scoped>
.layout-header {
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: rgba($kui-color-background, 0.75);
  border-bottom: 0;
  color: $kui-color-text-inverse;
  display: flex;
  flex: 0 0 $header-height; // must match the height
  flex-direction: column;
  height: $header-height; // must match flex height in app.vue
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 101;
}

.layout-header-content {
  align-items: center;
  display: flex;
  gap: $kui-space-60;
  height: 100%;
  justify-content: space-between;
  margin: $kui-space-0 $kui-space-auto;
  max-width: $content-max-width;
  padding: $kui-space-0 $kui-space-70;
  width: 100%;

  @media (min-width: $kui-breakpoint-phablet) {
    gap: $kui-space-70;
  }

  .left,
  .right,
  .center {
    align-items: center;
    display: flex;
    gap: $kui-space-60;
    height: 100%;

    @media (min-width: $kui-breakpoint-phablet) {
      gap: $kui-space-70;
    }
  }

  .left,
  .right {
    flex: 0 1 auto;

    @media (min-width: $kui-breakpoint-mobile) {
      flex: 1 1 0%;
    }
  }

  .center {
    flex-grow: 1;
    justify-content: flex-end;

    @media (min-width: $kui-breakpoint-mobile) {
      flex-grow: 0;
      justify-content: center;
    }
  }

  .right {
    justify-content: flex-end;
  }
}

.logo {
  align-items: center;
  color: $kui-color-text;
  display: flex;
  font-family: $kui-font-family-heading;
  font-size: $kui-font-size-50;
  font-weight: $kui-font-weight-bold;
  gap: $kui-space-40;
  line-height: $kui-line-height-50;
  user-select: none;

  > div {
    white-space: nowrap;
  }

  .konnect {
    display: none;

    @media (min-width: $kui-breakpoint-mobile) {
      display: inline;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $kui-color-text;
  }
}

.main-navigation {
  ul {
    align-items: center;
    display: flex;
    gap: $kui-space-80;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      align-items: center;
      display: flex;
      margin: 0;
      padding: 0;

      a {
        color: $kui-color-text;
        font-size: $kui-font-size-30;
        font-weight: $kui-font-weight-semibold;
        line-height: $kui-line-height-30;
        transition: color 0.2s ease-in-out;
        user-select: none;

        &:hover,
        &.router-link-active {
          color: $kui-color-text-primary;
        }
      }
    }
  }
}
</style>
