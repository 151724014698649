export const useLayoutStore = defineStore('layout', () => {
  /** On mobile, is the sidebar docs list visible */
  const sidebarLayoutDocsIsVisible = ref<boolean>(false)
  /** On mobile, is the sidebar document toc visible */
  const sidebarTocIsVisible = ref<boolean>(false)

  /** Close all sidebars on mobile */
  const closeAllSidebars = (): void => {
    sidebarLayoutDocsIsVisible.value = false
    sidebarTocIsVisible.value = false
  }

  /** If true, show the .global-page-overlay */
  const showPageOverlay = computed((): boolean => {
    return sidebarLayoutDocsIsVisible.value || sidebarTocIsVisible.value
  })

  watch(sidebarLayoutDocsIsVisible, (isVisible) => {
    if (isVisible) {
      sidebarTocIsVisible.value = false
    }
  })

  watch(sidebarTocIsVisible, (isVisible) => {
    if (isVisible) {
      sidebarLayoutDocsIsVisible.value = false
    }
  })

  const router = useRouter()
  router.afterEach(() => {
    // Close the sidebar when navigating to a new route
    closeAllSidebars()
  })

  useEventListener(window, 'resize', () => {
    closeAllSidebars()
  })

  return {
    sidebarLayoutDocsIsVisible,
    sidebarTocIsVisible,
    showPageOverlay,
    // Methods
    closeAllSidebars,
  }
})
