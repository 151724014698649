export const appHead = {"link":[{"rel":"apple-touch-icon","sizes":"152x152","href":"/browser/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/browser/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/browser/favicon-16x16.png"},{"rel":"mask-icon","href":"/browser/assets/safari-pinned-tab.svg","color":"#000933"},{"rel":"manifest","href":"/browser/site.webmanifest"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"robots","content":"index,follow"},{"name":"googlebot","content":"index,follow"},{"name":"application-name","content":"Kong Konnect Developer Portal Docs"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black"},{"name":"theme-color","content":"#000933"},{"name":"msapplication-TileColor","content":"#000933"},{"name":"format-detection","content":"telephone=no"},{"hid":"site_name","property":"og:site_name","content":"Kong Konnect Developer Portal Docs"},{"hid":"og:locale","property":"og:locale","content":"en_US"},{"hid":"twitter:card","property":"twitter:card","content":"summary_large_image"},{"property":"twitter:dnt","content":"on"},{"property":"twitter:widgets:csp","content":"on"},{"name":"commit","content":"a418124"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"trailingSlash":"remove","componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"