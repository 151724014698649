import { default as _91_46_46_46slug_93nlV4ffhJJtMeta } from "/work/portal/portal/apps/docs/pages/[...slug].vue?macro=true";
import { default as component_45stubp7CfKGZ7t6Meta } from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubp7CfKGZ7t6 } from "/work/portal/portal/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.14.0_jiti@2.3.3__ioredis@5.4.1__ebnisdrh3kwfxfl4quj4naecoi/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/work/portal/portal/apps/docs/pages/[...slug].vue")
  },
  {
    name: component_45stubp7CfKGZ7t6Meta?.name,
    path: "/components",
    component: component_45stubp7CfKGZ7t6
  }
]