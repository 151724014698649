<template>
  <footer class="layout-footer">
    <div class="footer-content-container">
      <div class="left" />
      <div class="center">
        <a
          href="https://konghq.com"
          target="_blank"
        >Kong</a>
        <a
          href="https://docs.konghq.com/konnect/dev-portal/"
          target="_blank"
        >Dev Portal Docs</a>
      </div>
      <div class="right" />
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.layout-footer {
  background-color: $kui-color-background;
  border-top: $kui-border-width-10 solid $kui-color-border;
  width: 100%;
}

.footer-content-container {
  color: $kui-color-text-neutral;
  display: flex;
  flex-direction: row;
  font-size: $kui-font-size-30;
  gap: $kui-space-70;
  line-height: $kui-line-height-30;
  margin: $kui-space-0 $kui-space-auto;
  max-width: $content-max-width;
  padding: $kui-space-70;
  width: 100%;

  .left,
  .right,
  .center {
    align-items: center;
    display: flex;
    gap: $kui-space-70;

    @media (min-width: $kui-breakpoint-phablet) {
      gap: $kui-space-100;
    }
  }

  .center {
    flex-grow: 1;
    justify-content: center;
  }

  .right {
    justify-content: flex-end;
  }
}
</style>
